<!-- 店铺-首页-右-产品 -->
<template>
    <div class="homeRightProduct">
        <el-card class="box-card" shadow="never">
            <div slot="header" class="clearfix">
                <span>产品图片</span>
            </div>
            <div class="box-item">
                <el-input v-model="middleInfo.title" placeholder="请输入标题" @input="titleChange"></el-input>
                <el-input v-model="middleInfo.subTitle" placeholder="请输入标题"></el-input>
                <el-input v-model="middleInfo.rightTitle" placeholder="请输入标题"></el-input>
                <el-button @click="dialogTableVisible = true">+添加商品</el-button>
            </div>
        </el-card>
        <div class="dialog-wrap">
            <el-dialog title="添加最新推荐" :visible.sync="dialogTableVisible" width="75%" >
                <div class="filter-wrap">
                    <div class="filter-item">
                        <div class="filter-title">商品名称:</div>
                        <el-input v-model="goodsName" placeholder="输入商品名称、商品编码" @input="changeInput"></el-input>
                    </div>
                    <div class="filter-item">
                        <div class="filter-title">商品类目:</div>
                        <el-cascader
                            @change="handleChange"
                            :options="options"
                            :props="{ checkStrictly: true }"
                            clearable
                            size="small"
                        ></el-cascader>
                    </div>
                </div>
                <div class="table-wra">
                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" height="360"
                        style="width: 100%"  @selection-change="handleSelectionChange" :row-key="getRowKeyOfTarget">
                        <el-table-column type="selection" reserve-selection width="55"></el-table-column>
                        <el-table-column prop="goodsNo" label="商品编号"></el-table-column>
                        <el-table-column prop="goodsName" label="商品名称"></el-table-column>
                        <el-table-column label="商品图片" width="120">
                            <template slot-scope="scope">
                            <img :src="scope.row.masterImageUrl" alt="" width="60" height="40" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="price" label="价格(元)" sortable></el-table-column>
                        <el-table-column label="库存">
                            <template slot-scope="scope">
                            <div>{{scope.row.inventory}} {{scope.row.units}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="collen" label="收藏" sortable></el-table-column>
                        <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip sortable></el-table-column>
                    </el-table>
                </div>
                <div class="page-wrap">
                    <div class="choose-all">
                        <!-- <el-checkbox v-model="checked">当页全选</el-checkbox> -->
                    </div>
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currpage"
                        :page-sizes="[5, 10, 20, 50,100]"
                        :page-size="pagesize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageInfo">
                    </el-pagination>
                </div>
                <div class="btn-wrap">
                    <el-button type="primary" @click="clickChoose">确定</el-button>
                    <el-button @click="toggleSelection()">取消</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
        pagesize: 5,
        currpage: 1,
        pageInfo: null,
        productList: [],
        checked: true,
        goodsName: '',
        tableData: [],
        dialogTableVisible: false,
        value: [],
        catalogId: null,
        options: [],
        middleInfo:{
            title: null,//标题
            subTitle: null,//副标题
            rightTitle: null//右侧信息
        }
    };
  },
  mounted(){
      this.getGoodsListbyBrand();
      this.getListCatalogType()
  },
  methods: {
        setIndexValue(value){
            this.tableData = value.goodsList;
            this.middleInfo = value.middleInfo
        },
        //输入标题
        titleChange(e){
            this.$emit('productChang',{productList: this.productList,middleInfo: this.middleInfo});
        },
        //搜索
        changeInput(e){
        this.goodsName = e
        this.getGoodsListbyBrand()
        },
        //确定
        clickChoose(){
            this.$emit('productChang',{productList: this.productList,middleInfo: this.middleInfo});
            if(this.productList){
                this.dialogTableVisible = false;
            }
        },
        //获取商品列表
        getGoodsListbyBrand(){
            let params = {
                keyword: this.goodsName,
                currentPage: this.currpage,
                pageSize: this.pagesize,
                status: '上架中',
                catalogId: this.catalogId
            }
            this.$API.getGoodsByShopList(params).then( res => {
                if(res.code === 0){
                this.tableData = res.data.list
                this.pageInfo = res.data.pageInfo.totalRow
                }
            })
        },
        // 获取商品类目结构
        getListCatalogType(){
        this.$API.getListCatalogType().then( res => {
            if(res.code === 0){
                let resultSecond;
                var result = res.data.map(o=>{
                    if(o.childrenNode){
                        resultSecond =  o.childrenNode.map(c=>{
                            return{
                                value: c.data.id, 
                                label: c.data.catalogName,
                            }
                        })
                    }
                    return{
                        value: o.data.id, 
                        label: o.data.catalogName,
                        children: resultSecond
                    }
                });
                this.options = result;
            }
        })
        },
        //取消
        toggleSelection() {
            this.$refs.multipleTable.clearSelection();
            this.dialogTableVisible = false;
        },
        //选中的值
        handleSelectionChange(val) {
            this.productList = val;
        },
        getRowKeyOfTarget(row) {
            return row.id
        },
        //选择商品类目
        handleChange(val){
            this.catalogId = val[val.length-1];
            this.getGoodsListbyBrand();
        },
        handleCurrentChange(cpage) {
            this.currpage = cpage;
            this.getGoodsListbyBrand();
        },
        handleSizeChange(psize) {
            this.pagesize = psize;
            this.getGoodsListbyBrand()
        },
  }
}

</script>
<style lang='scss' scoped>
.homeRightProduct{
    .box-card{
        width: 100%;
        margin: 0;
        .box-item{
            .item-title{
                font-size: 16px;
                color: #000;
                font-weight: 400;
            }
            .item-content{
                font-size: 14px;
                color: #999;
                padding: 10px 0;
            }
            .el-input{
                margin-bottom: 10px;
            }
        }
        .el-button{
            width: 100%;
        }
    }
    .dialog-wrap{
        .filter-wrap{
            display: flex;
            flex-flow: wrap;
            align-items: center;
            border-top: 1px solid #eee;
            padding: 0 20px;
            .filter-item{
                display: flex;
                flex-flow: wrap;
                align-items: center;
                padding-right: 40px;
                padding-top: 15px;
                .el-input{
                    width: 200px;
                }
                .filter-title{
                    padding-right: 10px;
                }
            }
        }
        .table-wra{
            padding: 0 20px;
        }
        .page-wrap{
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 10px;
            .choose-all{
                padding-left: 15px;
            }
        }
    }
    .btn-wrap{
            display: flex;
            justify-content: flex-end;
            padding: 20px;
            .el-button{
                width: 100px !important;
            }
        }
}

</style>