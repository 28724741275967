<!-- 店铺-首页-右-轮播图 -->
<template>
    <div class="homeRightSwiper">
        <el-card class="box-card" shadow="never">
            <div slot="header" class="clearfix">
                <span>图片广告</span>
            </div>
            <div class="box-item">
                <div class="item-title">添加图片</div>
                <div class="item-content">最多添加5个轮播图片，建议宽度750px</div>
            </div>
            <div class="box-btn">
                <el-button class="upload-demo" @click="uploadMainImage">+添加轮播图片</el-button>
            </div>
        </el-card>
        <div class="image-wrap">
            <div class="image-wrap-swiper" v-for="(item,index) in indexImgList" :key="index" >
                <el-image class="image-item" :src="item.url"></el-image>
                <div class="icon-wrap">
                    <i class="icon el-icon-delete-solid" @click="delSwiper(item)"></i>
                </div>
            </div>
        </div>
        <!-- 选择视频图片弹窗 -->
        <uploadData ref="uploadData" @selectImages="selectImages" :graphics="graphics" :uploadType="uploadType"></uploadData>
    </div>
</template>

<script>
import uploadData from '@/components/uploadData'
export default {
    components:{
        uploadData
    },
    data () {
        return {
            indexImgList: [],
            graphics: 100,  //上传图片数量
            uploadType: '',  //上传图片类型
        };
    },
    methods: {
        setIndexValue(value){
            this.indexImgList = value.indexImgList
        },
        // 移除图片
        delSwiper(item){
            this.indexImgList.forEach((v,i) =>{
                if(v.id === item.id){
                    this.indexImgList.splice(i, 1); 
                }
            })
            this.$emit('swiperChang',this.indexImgList)
        },
        //上传主图
        uploadMainImage(){
            this.graphics = 5;
            this.uploadType = 'swiper'
            this.$refs.uploadData.getDate()
        },
        //选中图片
        selectImages(value,type){
            switch (type) {
            case 'swiper':
                this.indexImgList = value;
                break;
            default:
                break;
            }
            this.$emit('swiperChang',this.indexImgList)
        },
    }
}

</script>
<style lang='scss' scoped>
.homeRightSwiper{
    .box-card{
        width: 100%;
        margin: 0;
        .box-item{
            .item-title{
                font-size: 16px;
                color: #000;
                font-weight: 400;
            }
            .item-content{
                font-size: 14px;
                color: #999;
                padding: 10px 0;
            }
        }
        .box-btn{
            width: 100%;
            .upload-demo{
                width: 100%;
                .el-button{
                    width: 100%;
                    margin-top: 10px;
                }
            }
            .box-btn-title{
                font-size: 14px;
                padding: 10px 0;
            }
            .box-btn-tag{
                font-size: 12px;
                color: #999;
                padding: 10px 0;
            }
        }
    }
    .image-wrap{
        display: flex;
        flex-wrap: wrap;
        .image-wrap-swiper{
            width: 115px;
            height: 115px;
            margin-top: 10px;
            margin-left: 20px;
            position: relative;
            .image-item{
                width: 100%;
                height: 100%;
            }
            .icon-wrap{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: none;
                font-size: 20px;
                color: seashell;
                background: rgba(0, 0, 0, 0.2);
                .icon{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    cursor: pointer;
                }
            }
        }
    }
    .image-wrap-swiper:hover{
        .icon-wrap{
            display: block;
        }
    }
}
</style>