<!-- 店铺-首页-右-4宫格 -->
<template>
    <div class="homeRightTabs">
        <el-card class="box-card" shadow="never">
            <div slot="header" class="clearfix">
                <span>4宫格</span>
            </div>
            <div class="box-item" v-show="tabIndex == 11">
                <div class="item-title">左上图文信息</div>
                <div class="box-btn">
                    <div class="box-btn-title">添加标题</div>
                    <el-input v-model="upleftInfo.title" placeholder="请输入标题文案" @input="inputChange"></el-input>
                    <div class="box-btn-tag">建议6个字以内</div>
                </div>
                <div class="box-btn">
                    <div class="box-btn-title">添加副标题</div>
                    <el-input v-model="upleftInfo.subTitle" placeholder="请输入副标题文案"></el-input>
                    <div class="box-btn-tag">建议10个字以内</div>
                </div>
                <div class="box-btn">
                    <div class="box-btn-title">添加背景图</div>
                    <el-button class="upload-demo" @click="upleftInfoImage">+添加背景图</el-button>
                    <div class="box-btn-tag">建议尺寸: 60*165px;</div>
                    <div class="box-image-wrap" v-if="upleftInfo.backgroundImg">
                        <el-image class="image" :src="upleftInfo.backgroundImg"></el-image>
                    </div>
                </div>
            </div>
            <div class="box-item" v-show="tabIndex == 12">
                <div class="item-title">右上图文信息</div>
                <div class="box-btn">
                    <div class="box-btn-title">添加标题</div>
                    <el-input v-model="uprightInfo.title" placeholder="请输入标题文案"></el-input>
                    <div class="box-btn-tag">建议6个字以内</div>
                </div>
                <div class="box-btn">
                    <div class="box-btn-title">添加副标题</div>
                    <el-input v-model="uprightInfo.subTitle" placeholder="请输入副标题文案"></el-input>
                    <div class="box-btn-tag">建议10个字以内</div>
                </div>
                <div class="box-btn">
                    <div class="box-btn-title">添加背景图</div>
                    <el-button class="upload-demo" @click="uprightInfoImage">+添加背景图</el-button>
                    <div class="box-btn-tag">建议尺寸: 60*165px;</div>
                    <div class="box-image-wrap" v-if="uprightInfo.backgroundImg">
                        <el-image class="image" :src="uprightInfo.backgroundImg"></el-image>
                    </div>
                </div>
            </div>
            <div class="box-item" v-show="tabIndex == 13">
                <div class="item-title">左下图文信息</div>
                <div class="box-btn">
                    <div class="box-btn-title">添加标题</div>
                    <el-input v-model="buttomleftInfo.title" placeholder="请输入标题文案"></el-input>
                    <div class="box-btn-tag">建议6个字以内</div>
                </div>
                <div class="box-btn">
                    <div class="box-btn-title">添加副标题</div>
                    <el-input v-model="buttomleftInfo.subTitle" placeholder="请输入副标题文案"></el-input>
                    <div class="box-btn-tag">建议10个字以内</div>
                </div>
                <div class="box-btn">
                    <div class="box-btn-title">添加背景图</div>
                    <el-button class="upload-demo" @click="buttomleftInfoImage">+添加背景图</el-button>
                    <div class="box-btn-tag">建议尺寸: 60*165px;</div>
                    <div class="box-image-wrap" v-if="buttomleftInfo.backgroundImg">
                        <el-image class="image" :src="buttomleftInfo.backgroundImg"></el-image>
                    </div>
                </div>
            </div>
            <div class="box-item" v-show="tabIndex == 14">
                <div class="item-title">右下图文信息</div>
                <div class="box-btn">
                    <div class="box-btn-title">添加标题</div>
                    <el-input v-model="buttomrightInfo.title" placeholder="请输入标题文案"></el-input>
                    <div class="box-btn-tag">建议6个字以内</div>
                </div>
                <div class="box-btn">
                    <div class="box-btn-title">添加副标题</div>
                    <el-input v-model="buttomrightInfo.subTitle" placeholder="请输入副标题文案"></el-input>
                    <div class="box-btn-tag">建议10个字以内</div>
                </div>
                <div class="box-btn">
                    <div class="box-btn-title">添加背景图</div>
                    <el-button class="upload-demo" @click="buttomrightInfoImage">+添加背景图</el-button>
                    <div class="box-btn-tag">建议尺寸: 60*165px;</div>
                    <div class="box-image-wrap" v-if="buttomrightInfo.backgroundImg">
                        <el-image class="image" :src="buttomrightInfo.backgroundImg"></el-image>
                    </div>
                </div>
            </div>
        </el-card>
        <!-- 选择视频图片弹窗 -->
        <uploadData ref="uploadData" @selectImages="selectImages" :graphics="graphics" :uploadType="uploadType"></uploadData>
    </div>
</template>

<script>
import uploadData from '@/components/uploadData'
export default {
    components:{
        uploadData
    },
    data () {
    return {
        //左上角信息
        upleftInfo:{
            title: '',          //标题
            subTitle: '',       //副标题
            backgroundImg: '',	//背景图url				
        },
        //右上角信息
        uprightInfo:{
            title:'',           
            subTitle: '',     
            backgroundImg:''	
        },
        //左下角信息
        buttomleftInfo:{
            title: '',
            subTitle: '',
            backgroundImg: ''	
        },
        //右下角信息
        buttomrightInfo:{
            title: '', 
            subTitle: '', 
            backgroundImg: ''	
        },

        graphics: 100,  //上传图片数量
        uploadType: '',  //上传图片类型
        tabIndex: 0
    };
  },
  methods: {
        setIndexValue(value){
            this.upleftInfo = value.upleftInfo;
            this.uprightInfo = value.uprightInfo;
            this.buttomleftInfo = value.buttomleftInfo;
            this.buttomrightInfo = value.buttomrightInfo;
        },
        //标题
        inputChange(e){
            this.$emit('tabsChang',{upleftInfo: this.upleftInfo,uprightInfo: this.uprightInfo,buttomleftInfo: this.buttomleftInfo,buttomrightInfo:this.buttomrightInfo})
        },
        //右下
        buttomrightInfoImage(){
            this.graphics = 1;
            this.uploadType = 'buttomrightInfo'
            this.$refs.uploadData.getDate()
        },
        // 左下
        buttomleftInfoImage(){
            this.graphics = 1;
            this.uploadType = 'buttomleftInfo'
            this.$refs.uploadData.getDate()
        },
        //上传右上图
        uprightInfoImage(){
            this.graphics = 1;
            this.uploadType = 'uprightInfo'
            this.$refs.uploadData.getDate()
        },
        //上传左上图
        upleftInfoImage(){
            this.graphics = 1;
            this.uploadType = 'upleftInfo'
            this.$refs.uploadData.getDate()
        },
        //选中图片
        selectImages(value,type){
            let url;
            value.forEach(v => {
                url = v.url
            });
            switch (type) {
            case 'upleftInfo':
                this.upleftInfo.backgroundImg = url;
                break;
            case 'uprightInfo':
                this.uprightInfo.backgroundImg = url;
                break;
            case 'buttomleftInfo':
                this.buttomleftInfo.backgroundImg = url;
                break;
            case 'buttomrightInfo':
                this.buttomrightInfo.backgroundImg = url;
                break;
            default:
                break;
            }
            console.log(this.indexData);
             this.$emit('tabsChang',{upleftInfo: this.upleftInfo,uprightInfo: this.uprightInfo,buttomleftInfo: this.buttomleftInfo,buttomrightInfo:this.buttomrightInfo})
        },
        setTableInde(index){
            this.tabIndex = index;
        }
  }
}

</script>
<style lang='scss' scoped>
.homeRightTabs{
    .box-card{
        width: 100%;
        margin: 0;
        .box-item{
            .item-title{
                font-size: 16px;
                color: #000;
                font-weight: 400;
            }
            .item-content{
                font-size: 14px;
                color: #999;
                padding: 10px 0;
            }
        }
        .box-btn{
            width: 100%;
            .upload-demo{
                width: 100%;
                .el-button{
                    width: 100%;
                    margin-top: 10px;
                }
            }
            .box-btn-title{
                font-size: 14px;
                padding: 10px 0;
            }
            .box-btn-tag{
                font-size: 12px;
                color: #999;
                padding: 10px 0;
            }
            .box-image-wrap{
                height: 90px;
                .image{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
</style>