<!-- 店铺-首页 -->
<template>
  <div class="store-home-wrap">
    <!-- 主体 -->
    <div class="box-card">
        <div class="card-title">
            <div class="title-hoem"></div>
            <div class="title-btn">
                <!-- <el-button size="small">预览效果</el-button> -->
                <div class="title">首页装修</div>
                <el-button size="small" type="primary" @click="saveIndexMini">保存</el-button>
            </div>
        </div>
        <div class="content">
            <div class="left-wraps">
                <home-left ref="homeLeft" @clickItem="clickItem"></home-left>
            </div>
            <div class="right-wraps">
                <home-right ref="homeRight" @indexChang="indexChang"></home-right>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import homeLeft from "./components/homeLeft";
import homeRight from "./components/homeRight";
import pageMixin from '@/internal/pageMixin'
export default {
    mixins:[pageMixin],
    components: {
        homeRight,
        homeLeft
    },
    data () {
        return {
            pageMixin_index:'sHomeDecoration',
            posterImg: '',
            indexImgList: null,
            goodsList: null,
            //左上角信息
            upleftInfo:{
                title: '',          //标题
                subTitle: '',       //副标题
                backgroundImg: '',	//背景图url				
            },
            //右上角信息
            uprightInfo:{
                title:'',           
                subTitle: '',     
                backgroundImg:''	
            },
            //左下角信息
            buttomleftInfo:{
                title: '',
                subTitle: '',
                backgroundImg: ''	
            },
            //右下角信息
            buttomrightInfo:{
                title: '', 
                subTitle: '', 
                backgroundImg: ''	
            },
            dataList: {},
            middleInfo: {},
        };
    },
    mounted(){
        this.getInfo()
    },
    methods: {
        //信息详情
        getInfo(){
            this.$API.getHomePage().then( res => {
                console.log(res);
                if(res.code === 0){
                    this.dataList = res.data;
                    this.$nextTick(()=>{
                        this.$refs.homeLeft.setIndexValue(this.dataList);
                        this.$refs.homeRight.setIndexValue(this.dataList);
                    })
                }
            })
        },
        //轮播图设置
        indexChang(value){
            console.log(value);
            this.$nextTick(() =>{
                this.dataList = value;
                let swiperIds = value.indexImgList
                let sIds = []
                if(swiperIds){
                    swiperIds.forEach(v => {
                        sIds.push(v.id)
                    });
                }

                let goodsList = value.goodsList
                let sgoodsIds = null
                console.log(goodsList);
                if(goodsList.length){
                    sgoodsIds = []
                    if(goodsList){
                        goodsList.forEach(v => {
                            sgoodsIds.push(v.id)
                        });
                    }
                    this.goodsList = sgoodsIds;
                }else{
                    sgoodsIds = null
                }
                
                this.posterImg = value.posterImg;
                this.indexImgList = sIds;
                this.upleftInfo = value.upleftInfo;
                this.uprightInfo = value.uprightInfo;
                this.buttomleftInfo = value.buttomleftInfo;
                this.buttomrightInfo = value.buttomrightInfo;
                this.middleInfo = value.middleInfo


                this.$refs.homeLeft.setIndexValue(this.dataList)
            })
        },
        
        //保存
        saveIndexMini(){
            let params = {
                posterImg: this.posterImg,
                indexImgList: this.indexImgList,
                goodsList: this.goodsList,
                middleInfo: this.middleInfo,
                upleftInfo: this.upleftInfo,
                uprightInfo: this.uprightInfo,
                buttomleftInfo: this.buttomleftInfo,
                buttomrightInfo: this.buttomrightInfo
            }
            console.log("params",params);
            this.$API.saveOrUpdateHome(params).then( res => {
                if(res.code === 0){
                    this.getInfo();
                    this.$message.success('店铺装修保存成功')
                }
            })
        },
        clickItem(index){
            this.$nextTick(() =>{
                this.$refs.homeRight.setValue(index)
            })
        }
    }
}

</script>
<style lang='scss'>
.store-home-wrap{
    height: 100%;
    overflow: hidden;
    .box-card{
        margin: 0 20px;
        .card-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            .title-hoem{
                flex: 3;
            }
            .title-btn{
                flex: 1;
                background: #fff;
                padding: 10px 0;
                margin: 5px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .el-button{
                    margin: 0 20px;
                    width: 70px;
                }
                .title{
                    margin: 0 20px;
                    font-size: 16px;
                }
            }
        }
        .content{
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: calc(100vh - 115px);
            .left-wraps{
                flex: 3;
                height: 100%;
            }
            .right-wraps{
                flex: 1;
                background: #fff;
                height: 100%;
                overflow: hidden;
                .el-upload{
                    width: 100% !important;
                }
                .el-input__inner{
                    height: 30px !important;
                    font-size: 12px;
                }
                .el-upload-list__item{
                    transition: all .2s cubic-bezier(.55,0,.1,1);
                }
                .el-list-enter-active,.el-list-leave-active{
                    transition: all 0.2s;
                }
            }
        }
    }
}

</style>