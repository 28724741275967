<!-- 店铺-首页-右 -->
<template>
    <div class="home-right-wrap">
        <!-- 轮播图 -->
        <div class="homeRightSwiper-wrap" v-show="indexValue == 0">
            <homeRightSwiper @swiperChang="swiperChang" ref="homeRightSwiper"></homeRightSwiper>
        </div>
        <!-- 9宫格 -->
        <div class="homeRightTabs-wrap" v-show="indexValue > 10">
            <homeRightTabs @tabsChang="tabsChang" ref="homeRightTabs"></homeRightTabs>
        </div>
        <!-- 海报 -->
        <div class="homeRightPosters-wrap" v-show="indexValue == 2">
            <homeRightPosters @postersChang="postersChang" ref="homeRightPosters"></homeRightPosters>
        </div>
        <!-- 产品 -->
        <div class="homeRightProduct-wrap" v-show="indexValue == 3">
            <homeRightProduct @productChang="productChang" ref="homeRightProduct"></homeRightProduct>
        </div>
    </div>
</template>

<script>
import homeRightTabs from "./homeRightTabs";
import homeRightSwiper from "./homeRightSwiper";
import homeRightPosters from "./homeRightPosters";
import homeRightProduct from "./homeRightProduct";
export default {
    components:{
        homeRightTabs,
        homeRightSwiper,
        homeRightPosters,
        homeRightProduct
    },
    data () {
        return {
           indexValue: 0,
           indexImgList: [],
           upleftInfo: {},
           uprightInfo: {},
           buttomleftInfo: {},
           buttomrightInfo:{},
           posterImg: '',
           goodsList: [{},{}],
           middleInfo: {}
        };
    },
    methods: {
        setIndexValue(value){
            if(value){
                this.$nextTick(()=>{
                    this.indexImgList = value.indexImgList;
                    this.upleftInfo = value.upleftInfo;
                    this.uprightInfo = value.uprightInfo;
                    this.buttomleftInfo = value.buttomleftInfo;
                    this.buttomrightInfo = value.buttomrightInfo;
                    this.posterImg = value.posterImg;
                    this.goodsList = value.goodsList;
                    this.middleInfo = value.middleInfo;
                    this.$refs.homeRightSwiper.setIndexValue(value);
                    this.$refs.homeRightTabs.setIndexValue(value);
                    this.$refs.homeRightPosters.setIndexValue(value);
                    this.$refs.homeRightProduct.setIndexValue(value);
                })
            }
        },
        // 商品
        productChang(value){
            this.goodsList = value.productList;
            this.middleInfo = value.middleInfo;
            this.$nextTick(()=>{
                this.$emit("indexChang",{indexImgList: this.indexImgList,upleftInfo: this.upleftInfo,uprightInfo: this.uprightInfo,middleInfo: this.middleInfo,
                    buttomleftInfo: this.buttomleftInfo,buttomrightInfo:this.buttomrightInfo,posterImg: this.posterImg,goodsList: this.goodsList})
            })
        },
        // 海报
        postersChang(value){
            this.posterImg = value;
            this.$nextTick(()=>{
                this.$emit("indexChang",{indexImgList: this.indexImgList,upleftInfo: this.upleftInfo,uprightInfo: this.uprightInfo,middleInfo: this.middleInfo,
                    buttomleftInfo: this.buttomleftInfo,buttomrightInfo:this.buttomrightInfo,posterImg: this.posterImg,goodsList: this.goodsList})
            })
        },
        // 四宫格
        tabsChang(value){
            this.upleftInfo = value.upleftInfo;
            this.uprightInfo = value.uprightInfo;
            this.buttomleftInfo = value.buttomleftInfo;
            this.buttomrightInfo = value.buttomrightInfo;
       
            this.$nextTick(()=>{
                this.$emit("indexChang",{indexImgList: this.indexImgList,upleftInfo: this.upleftInfo,uprightInfo: this.uprightInfo,middleInfo: this.middleInfo,
                    buttomleftInfo: this.buttomleftInfo,buttomrightInfo:this.buttomrightInfo,posterImg: this.posterImg,goodsList: this.goodsList})
            })
        },
        // 轮播图
        swiperChang(value){
            this.indexImgList = value
            this.$nextTick(()=>{
                this.$emit("indexChang",{indexImgList: this.indexImgList,upleftInfo: this.upleftInfo,uprightInfo: this.uprightInfo,middleInfo: this.middleInfo,
                    buttomleftInfo: this.buttomleftInfo,buttomrightInfo:this.buttomrightInfo,posterImg: this.posterImg,goodsList: this.goodsList})
            })
        },
        setValue(index){
            this.indexValue = index;
            this.$nextTick(() =>{
                this.$refs.homeRightTabs.setTableInde(index)
            })
        },
    },
}

</script>
<style lang='scss' scoped>
.home-right-wrap{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    
}
.home-right-wrap::-webkit-scrollbar {
    display: none;
}
.hide .el-upload--picture-card {
    display: none;
}

</style>