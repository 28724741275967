<!-- 店铺-首页-左 -->
<template>
    <div class="home-left-wrap">
        <div class="content-wrap" >
            <!-- 轮播图 -->
            <div class="swiper" @click="clickItem(0)" :class="isSwiper? 'activeSwiper':''">
                <el-carousel height="200px">
                    <el-carousel-item v-for="(item,index) in indexImgList" :key="index">
                        <el-image class="images" :src="item.url" ></el-image>
                    </el-carousel-item>
                </el-carousel>
                <div v-if="!indexImgList" class="sw-name">轮播图片</div>
            </div>
            <!-- 四宫格 -->
            <div class="tabs">
                <div class="tabs-ul">
                    <div class="tabs-li" @click="clickItem(11)" :class="isTabs1? 'tabs1':''">
                        <div v-if="upleftInfo">
                            <el-image v-if="upleftInfo.backgroundImg" class="tab-img" :src="upleftInfo.backgroundImg"></el-image>
                            <div class="tab-title">
                                <div class="title">{{upleftInfo.title}}</div>
                                <div>{{upleftInfo.subTitle}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="tabs-li" @click="clickItem(12)" :class="isTabs2? 'tabs2':''">
                        <div v-if="uprightInfo">
                            <el-image v-if="uprightInfo.backgroundImg" class="tab-img" :src="uprightInfo.backgroundImg"></el-image>
                            <div class="tab-title">
                                <div class="title">{{uprightInfo.title}}</div>
                                <div>{{uprightInfo.subTitle}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="tabs-li" @click="clickItem(13)" :class="isTabs3? 'tabs3':''">
                        <div v-if="buttomleftInfo">
                            <el-image v-if="buttomleftInfo.backgroundImg" class="tab-img" :src="buttomleftInfo.backgroundImg"></el-image>
                            <div class="tab-title">
                                <div class="title">{{buttomleftInfo.title}}</div>
                                <div>{{buttomleftInfo.subTitle}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="tabs-li" @click="clickItem(14)" :class="isTabs4? 'tabs4':''">
                        <div v-if="buttomrightInfo">
                            <el-image v-if="buttomrightInfo.backgroundImg" class="tab-img" :src="buttomrightInfo.backgroundImg"></el-image>
                            <div class="tab-title">
                                <div class="title">{{buttomrightInfo.title}}</div>
                                <div>{{buttomrightInfo.subTitle}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 海报 -->
            <div class="posters-wrap" @click="clickItem(2)" :class="isPoster? 'activePoster':''">
                <div class="poster-item">
                    <el-image v-if="posterImg" class="poster-img" :src="posterImg" ></el-image>
                </div>
            </div>
            <!-- 产品 -->
            <div class="product-wrap" @click="clickItem(3)" :class="isProduct? 'activeProduct':''">
                <div class="product-title">
                    <div class="left-title">
                        <div class="big-tit">{{middleInfo.title}}</div>
                        <div class="line"></div>
                        <div class="sm-tit">{{middleInfo.subTitle}}</div>
                    </div>
                    <div class="right-title">{{middleInfo.rightTitle}}</div>
                </div>
                <!-- 产品列表 -->
                <div class="product-wrap-content" v-if="goodsList">
                    <div class="product-item" v-for="(item,index) in goodsList" :key="index">
                        <div class="product-img">
                            <el-image v-if="item.masterImageUrl" class="img" :src="item.masterImageUrl" mode="aspectFill" ></el-image>
                        </div>
                        <div class="product-name">{{item.goodsName}}</div>
                    </div>
                </div>
                <div class="product-no" v-else>暂无列表数据</div>
            </div>
        </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
        indexImgList: [],
        //左上角信息
        upleftInfo:{
            title: '',          //标题
            subTitle: '',       //副标题
            backgroundImg: '',	//背景图url				
        },
        //右上角信息
        uprightInfo:{
            title:'',           
            subTitle: '',     
            backgroundImg:''	
        },
        //左下角信息
        buttomleftInfo:{
            title: '',
            subTitle: '',
            backgroundImg: ''	
        },
        //右下角信息
        buttomrightInfo:{
            title: '', 
            subTitle: '', 
            backgroundImg: ''	
        },
        middleInfo: {},
        posterImg: '',
        goodsList: [{},{}],
        isSwiper: true,
        isTabs1: false,
        isTabs2: false,
        isTabs3: false,
        isTabs4: false,
        isPoster: false,
        isProduct: false
    };
  },
  methods: {
        setIndexValue(value){
            if(value){
                this.indexImgList = value.indexImgList;
                this.upleftInfo = value.upleftInfo;
                this.uprightInfo = value.uprightInfo;
                this.buttomleftInfo = value.buttomleftInfo;
                this.buttomrightInfo = value.buttomrightInfo;
                this.posterImg = value.posterImg;
                this.middleInfo = value.middleInfo;
                if(value.goodsList){
                    value.goodsList.forEach(v => {
                    if(v.masterImageName){
                        v.masterImageName = v.masterImageName.substring(0,v.masterImageName.indexOf("."));
                    }
                    });
                    this.goodsList = value.goodsList
                }
            }
        },
        //点击轮播图
        clickItem(index){
            if(index == 0){
                this.isSwiper = true;
                this.isTabs1 = false;
                this.isTabs2 = false;
                this.isTabs3 = false;
                this.isTabs4 = false;
                this.isPoster = false;
                this.isProduct = false;
            }else if(index == 11){
                this.isSwiper = false;
                this.isTabs1 = true;
                this.isTabs2 = false;
                this.isTabs3 = false;
                this.isTabs4 = false;
                this.isPoster = false;
                this.isProduct = false;
            }else if(index == 12){
                this.isSwiper = false;
                this.isTabs2 = true;
                this.isTabs1 = false;
                this.isTabs3 = false;
                this.isTabs4 = false;
                this.isPoster = false;
                this.isProduct = false;
            }else if(index == 13){
                this.isSwiper = false;
                this.isTabs3 = true;
                this.isTabs2 = false;
                this.isTabs1 = false;
                this.isTabs4 = false;
                this.isPoster = false;
                this.isProduct = false;
            }else if(index == 14){
                this.isSwiper = false;
                this.isTabs4 = true;
                this.isTabs2 = false;
                this.isTabs3 = false;
                this.isTabs1 = false;
                this.isPoster = false;
                this.isProduct = false;
            }else if(index == 2){
                this.isPoster = true;
                this.isSwiper = false;
                this.isTabs1 = false;
                this.isTabs2 = false;
                this.isTabs3 = false;
                this.isTabs4 = false;
                this.isProduct = false;
            }else if(index == 3){
                this.isProduct = true;
                this.isPoster = false;
                this.isSwiper = false;
                this.isTabs1 = false;
                this.isTabs2 = false;
                this.isTabs3 = false;
                this.isTabs4 = false;
            }
            this.$emit("clickItem",index)
        },
  }
}

</script>
<style lang='scss'>
.home-left-wrap{
    width: 360px;
    height: 90%;
    background: #fff;
    margin: 0px auto;
    box-shadow: 0 0px 1px 1px rgba(100,100,100,0.1);
    .content-wrap{
        height: 100%;
        width: 100%;
        overflow-y: auto;
    }
    .swiper{
        border: 1px solid rgba(100,100,100,0);
        height: 200px;
        background: #eee;
        position: relative;
        .images{
            width: 100%;
            height: 100%;
        }
        .sw-name{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            font-size: 16px;
            color: #999;
        }
    }
    .tabs{
        // border: 1px solid rgba(100,100,100,0);
        .tabs-ul{
            display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			box-sizing: border-box;
            margin: 10px 10px 6px 10px;
            .tabs-li{
                width: calc((100% - 10px)/2);
                position: relative;
                height: 60px;
                margin-bottom: 6px;
                border-radius: 5px;
                overflow: hidden;
                background: #eee;
                .tab-img{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                }
                .tab-title{
                    position: absolute;
                    z-index: 9;
                    color: #fff;
                    font-size: 12px;
                    padding-top: 12px;
                    padding-left: 10px;
                    .title{
                        font-size: 14px;
                        
                    }
                }
            }
        }
    }
    .posters-wrap{
        border: 1px solid rgba(100,100,100,0);
        height: 70px;
        .poster-item{
            border-radius: 6px;
            overflow: hidden;
            margin: 0 10px;
            height: 100%;
            background-color: #eee;
            .poster-img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .product-wrap{
        border: 1px solid rgba(100,100,100,0);
        padding: 10px;
        .product-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #000;
            padding: 8px 10px;
            .left-title{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .big-tit{
                    font-size: 16px;
                    font-weight: bold;
                    color: #fff;
                }
                .line{
                    width: 1px;
                    height: 15px;
                    background-color: #fff;
                    margin: 0 10px;
                }
                .sm-tit{
                    font-size: 14px;
                    color: #fff;
                }
            }
            .right-title{
                font-size: 16px;
                color: #fff;
            }
        }
        .product-wrap-content{
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            box-sizing: border-box;
            margin-top: 6px;
            border-top: 1px solid #999;
            padding-top: 20px;
            .product-item{
                width: calc((100% - 10px)/2);
                display: flex;
                flex-direction: column;
                padding-bottom: 10px;
                .product-img{
                    width: 100%;
                    height: 160px;
                    background-color: #eee;
                    border-radius: 6px;
                    overflow: hidden;
                    .img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .product-name{
                    font-size: 15px;
                    padding: 6px 0px;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    white-space:nowrap;
                }
            }
        }
        .product-no{
            text-align: center;
            color: #999;
            font-size: 18px;
            padding: 60px;
        }
    }
    .activeSwiper,.activePoster{
        border-color: rgb(103, 155, 252);
    }
    .activeProduct{
         border: 1px solid rgb(103, 155, 252);
    } 
    .tabs1,.tabs2,.tabs3,.tabs4{
         border: 2px solid rgb(103, 155, 252);
    } 
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background: #c1c1c1;
        background-color: rgba(239, 243, 248, 0.9);   //滚动框颜色
    }
    ::-webkit-scrollbar-track   {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(239, 243, 248, 0.3);   //滚动条颜色
        background-color: #c5c8cd;
    }
    ::-webkit-scrollbar-thumb:hover {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.1);
    } 
}

</style>