<!-- 店铺-首页-右-海报 -->
<template>
    <div class="homeRightPosters">
        <el-card class="box-card" shadow="never">
            <div slot="header" class="clearfix">
                <span>海报图片</span>
            </div>
            <div class="box-item">
                <div class="item-title">添加图片</div>
                <div class="item-content">只能添加1张海报图片</div>
            </div>
            <div class="box-btn">
                <el-button class="upload-demo" @click="uploadMainImage">+添加海报图片</el-button>
            </div>
        </el-card>
        <div class="image-wrap-swiper" v-if="posterImg">
            <el-image class="image-item" :src="posterImg"></el-image>
            <div class="icon-wrap">
                <i class="icon el-icon-delete-solid" @click="delSwiper"></i>
            </div>
        </div>
        <!-- 选择视频图片弹窗 -->
        <uploadData ref="uploadData" @selectImages="selectImages" :graphics="graphics" :uploadType="uploadType"></uploadData>
    </div>
</template>

<script>
import uploadData from '@/components/uploadData'
export default {
    components:{
        uploadData
    },
    data () {
        return {
            posterImg: '',
            graphics: 100,  //上传图片数量
            uploadType: '',  //上传图片类型
        }; 
    },
    methods: {
        setIndexValue(value){
            this.posterImg = value.posterImg
        },
        delSwiper(){
            this.posterImg = '';
            this.$emit('postersChang',this.posterImg)
        },
        //上传主图
        uploadMainImage(){
            this.graphics = 1;
            this.uploadType = 'posters'
            this.$refs.uploadData.getDate()
        },
        //选中图片
        selectImages(value,type){
            let url;
            value.forEach(v => {
                url = v.url
            });
            switch (type) {
            case 'posters':
                this.posterImg = url;
                break;
            default:
                break;
            }
            this.$emit('postersChang',this.posterImg)
        },
    }
}

</script>
<style lang='scss' scoped>
.homeRightPosters{
    .box-card{
        width: 100%;
        margin: 0;
        .box-item{
            .item-title{
                font-size: 16px;
                color: #000;
                font-weight: 400;
            }
            .item-content{
                font-size: 14px;
                color: #999;
                padding: 10px 0;
            }
        }
        .box-btn{
            width: 100%;
            .upload-demo{
                width: 100%;
                .el-button{
                    width: 100%;
                    margin-top: 10px;
                }
            }
            .box-btn-title{
                font-size: 14px;
                padding: 10px 0;
            }
            .box-btn-tag{
                font-size: 12px;
                color: #999;
                padding: 10px 0;
            }
        }
    }
    .image-wrap-swiper{
        width: 100%;
        height: 80px;
        margin-top: 10px;
        position: relative;
        .image-item{
            width: 100%;
            height: 100%;
        }
        .icon-wrap{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: none;
            font-size: 20px;
            color: seashell;
            background: rgba(0, 0, 0, 0.2);
            .icon{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                cursor: pointer;
            }
        }
    }
    .image-wrap-swiper:hover{
        .icon-wrap{
            display: block;
        }
    }
}
</style>